<template>
  <div class="relative flex flex-col select-none items-center gap-1" @contextmenu.prevent="emit('item-context')">
    <div class="aspect-[1/1] w-full relative flex cursor-pointer rounded-full transition-all" @click="emit('item-click')" :class="person.selected ? 'border-4 border-heroblue' : null">

      <div v-if="person.selected" class="absolute right-2 top-2 bg-heroblue text-white flex-center rounded-full w-8 h-8 z-10 border-2 border-white">
        <u-icon name="i-ri-check-line" class="w-4 h-4" />
      </div>

      <div v-if="person.isHidden" class="absolute z-10 w-full aspect-[1/1] rounded-full bg-charcoal/50 flex-center">
        <u-icon :name="COMMON_ICONS.personHide" class="w-2/5 h-2/5 text-white/75" />
      </div>

      <people-avatar
        :chip-color="chipColor"
        :chip-text="chipText"
        size="md"
        :person="person"
        :ui="avatarUiConfig"
      />
    </div>

    <people-rename-merge
      v-if="!hideName"
      :person="person"
      @merged="onMerged"
      class="w-full"
      :disabled="disableEdit"
      :rename-only="renameOnly"
    >
      <div :class="[person.isHidden ? 'text-gray-400 dark:text-white' : 'text-charcoal dark:text-white', textSize]" class="text-center break-long-string items-center leading-tight line-clamp-2 relative py-1 flex flex-col gap-0.5 justify-center">
        <template v-if="person.name">
          <span :class="[nameStyle, !disableEdit ? 'hover:underline' : null]">{{person.name}}</span>
        </template>

        <span class="italic text-gray-400 dark:text-gray-300" :class="!disableEdit ? 'hover:underline' : null" v-else>Unnamed</span>
      </div>
    </people-rename-merge>

    <p class="text-xs text-gray-400 text-center -mt-1" v-if="showFileCount">{{person.files_count}} Files</p>
  </div>
</template>

<script setup>
  const emit = defineEmits(['item-click', 'merged', 'item-context']);
  const props = defineProps({
    person: Object,
    textSize: {
      type: String,
      default: 'text-base'
    },
    nameStyle: {
      type: String,
      default: 'font-semibold'
    },
    hideName: Boolean,
    chipColor: String,
    chipText: String,
    ui: Object,
    disableEdit: Boolean,
    renameOnly: Boolean,
    showFileCount: Boolean,
    showMeBadge: Boolean,
    selected: Boolean
  });

  const avatarUiConfig = computed(() => {
    const uiConfigBase = {
      wrapper: 'shadow-md !w-full !h-auto aspect-[1/1]',
      size: {
        md: '!w-full !h-auto aspect-[1/1]'
      }
    };

    return Object.assign(uiConfigBase, props.ui);
  });

  const showUserLinkBadge = computed(() => props.showMeBadge && props.person.isCurrentUser);

  function onMerged({person}) {
    emit('merged', {person});
  }
</script>
