<template>
  <div>
    <core-modal-header>{{heading}}</core-modal-header>

      <div v-if="step === 1">
        <core-modal-body>
          <div class="flex-1 overflow-y-auto scrollbar-light -mr-2 sm:-mr-4 pr-2 sm:pr-4 scrollbar-gutter-stable max-h-[72vh]" ref="listEl">
            <transition-group name="f-slide-fade">
              <core-contextual-loading-box v-if="pending" key="loading" loading-text="Loading People" />
              <core-contextual-error-box v-else-if="error" key="error" :action="refresh" />

              <core-empty-state
                v-else-if="!filteredPeople.length"
                heading="No People"
                description="No people were found."
                :icon="COMMON_ICONS.person"
              />

              <core-list v-else :items="filteredPeople" :scroller-elem="listEl" :disable-approach-bottom="pending || isLoadingMore || !hasMore" @approach-bottom="getMore" container-class="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-5 md:grid-cols-6" grid-gap-class="gap-3" item-class="relative" :section-size="30">
                <template #default="{ item: person }">
                  <people-list-item
                    :selected="person.selected"
                    text-size="text-sm"
                    :person="person"
                    :key="person.id"
                    disable-edit
                    @item-click="toggleItem(person)"
                  />
                </template>
              </core-list>
            </transition-group>
          </div>
        </core-modal-body>

        <core-modal-footer>
          <u-button @click="() => modal.close()" variant="soft" color="charcoal">Cancel</u-button>
          <u-button @click="step = 2" :disabled="!selectedPeople?.length">Continue to Review</u-button>
        </core-modal-footer>
      </div>

      <div v-else>
        <core-modal-body>

          <p class="text-xl text-center mb-4">Are you sure you want to merge...</p>

          <core-list :items="selectedPeople" container-class="flex flex-wrap justify-center" grid-gap-class="gap-0" item-class="w-1/2 xs:w-1/3 sm:w-1/5 md:w-1/6 relative p-1.5" :section-size="30">
            <template #default="{ item: person }">
              <people-list-item
                :selected="person.selected"
                text-size="text-sm"
                :person="person"
                :key="person.id"
                disable-edit
                @item-click="toggleItem(person)"
              />
            </template>
          </core-list>

          <u-divider label="into" icon="i-ri-arrow-right-line" class="my-4" :ui="{label: 'text-lg font-semibold'}" />

          <div class="max-w-48 mx-auto mb-10">
            <people-list-item
              :person="person"
              disable-edit
            />
          </div>

          <u-alert color="red" variant="solid" :icon="COMMON_ICONS.information" :ui="{title: 'text-base', description: 'text-base', icon: {base: 'w-8 h-8'}}">
            <template #title>This action cannot be reversed.</template>
            <template #description>This action will permanently combine the selected faces with the destination person. All information you've added to the selected people will be lost.</template>
          </u-alert>
        </core-modal-body>

        <core-modal-footer>
          <u-button @click="() => modal.close()" variant="soft" color="charcoal">Cancel</u-button>
          <u-button @click="step = 1" variant="soft">Back to Previous</u-button>
          <u-button @click="merge" :disabled="!selectedPeople?.length" :loading="isSaving">
            Merge People
            <template v-if="isSaving">{{` (${mergeProgress}/${selectedPeople.length})`}}</template>
          </u-button>
        </core-modal-footer>
      </div>

  </div>

</template>

<script setup>
  import {makePeopleStore} from '~/stores/people.js';
  import {storeToRefs} from 'pinia';

  const props = defineProps({
    person: Object,
    modal: Object
  });

  const BULK_MERGE_LIMIT = 18;

  const emit = defineEmits(['success']);

  const step = ref(1);

  const listEl = ref();

  const peopleStoreId = 'showHidePeopleStore';
  const peopleStore = makePeopleStore(peopleStoreId)();
  const {people, hasMore} = storeToRefs(peopleStore);

  const {refresh, pending, error} = await useLazyAsyncData(
    'bulk-merge',
    () => peopleStore.getPeople({
      displayStatus: [PEOPLE_DISPLAY_STATUS.visible],
      order: PEOPLE_SORT_OPTIONS[0].param
    })
  );

  const isLoadingMore = ref(false);

  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    isLoadingMore.value = true;

    try {
      await peopleStore.getPeople({nextPage: true});
    } catch (e) {
      useErrorToast().add({description: 'There was an issue getting more people.'});
    } finally {
      isLoadingMore.value = false;
    }
  }

  function toggleItem(person) {
    if (selectedPeople.value.length >= BULK_MERGE_LIMIT && !person.selected && !isSaving) {
      useInfoToast().add({description: `You can only merge up to ${BULK_MERGE_LIMIT} people at a time.`});
      return;
    }
    person.selected = Boolean(!person.selected);
  }

  const heading = computed(() => {
    if (step.value === 1) {
      return `Step 1: Choose Faces to Merge with ${props.person.name || 'Unnamed'}`;
    } else if (step.value === 2) {
      return 'Step 2: Review and Merge';
    }
  });

  const filteredPeople = computed(() => people?.value?.filter(person => !person.isCurrentUser && person.id !== props.person.id && person.images));
  const selectedPeople = computed(() => filteredPeople?.value?.filter(person => person.selected));
  let mergeProgress = ref(0);
  const isSaving = ref(false);

  async function merge() {
    let failureCount = 0;

    isSaving.value = true;
    for (let i = 0; i < selectedPeople.value.length; i++) {
      const person = selectedPeople.value[i];
      try {
        await usePeopleApi().mergePeople({
          source: person,
          destination: props.person
        });
      } catch (e) {
        failureCount++;
        console.log(`Error merging person with ID ${person.id}:`, e);
      } finally {
        mergeProgress.value++;
      }
    }

    isSaving.value = false;
    emit('success', {error: failureCount || null});
  }

  onUnmounted(() => {
    peopleStore.clearPeople();
  });
</script>
